import React, { useState, useEffect } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import { navigate } from "gatsby-link";
import Swal from "sweetalert2";

import PrivateMiducRoute from "~/src/components/Authentication/MiducPrivateRoute";
import TokenValidator from "~/src/components/Authentication/MiducTokenValidator";
import CouponForm from "~/src/components/coupons/CouponForm";
import Layout from "~/src/components/Layout";
import { Country } from "~/src/constants/countries";
import { baseCoupon, Coupon } from "~/src/types/coupons";
import { LabService, SelectedLabService } from "~/src/types/lab-services";
import { getCoupon, getCouponLabServices } from "~/src/utils/api-coupons";
import { fetchLabServices } from "~/src/utils/api-lab-services";
import namespaced from "~/src/utils/debug";

const debug = namespaced("coupons/edit");

// Components

interface Props {
  id: string;
}

const EditCoupon = ({ id }: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [coupon, setCoupon] = useState<Coupon>(baseCoupon);
  const [allLabServices, setAllLabServices] = useState<LabService[]>([]);
  const [labServicesHash, setLabServicesHash] = useState<{ [key: string]: SelectedLabService }>({});
  const [displayedLabServices, setDisplayedLabServices] = useState<LabService[] | undefined>([]);
  const [country, setCountry] = useState<Country | null>(Country.CHILE);

  const fetchData = async () => {
    setLoading(true);
    try {
      const couponData = await getCoupon(id);
      if (couponData) {
        // set coupon and discount to %
        setCoupon({
          ...couponData,
          discount: couponData.discount * 100,
          begin_date: new Date(couponData.begin_date),
          expiration_date: new Date(couponData.expiration_date),
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "No se pudo obtener los valores para el cupón con id " + id,
          icon: "error",
          willClose: () => {
            navigate(`/coupons`);
          },
        });
      }
      const bloodLabServicesData = (await fetchLabServices({ category: "blood", country, query: null })) || [];
      const covidLabSerivcesData = (await fetchLabServices({ category: "covid", country, query: null })) || [];
      const urineLabServicesData = (await fetchLabServices({ category: "urine", country, query: null })) || [];
      const vaccinesLabSerivcesData = (await fetchLabServices({ category: "vaccine", country, query: null })) || [];
      const couponLabServices = await getCouponLabServices(parseInt(id));
      const allServicesData = [
        ...bloodLabServicesData,
        ...covidLabSerivcesData,
        ...urineLabServicesData,
        ...vaccinesLabSerivcesData,
      ];
      setAllLabServices(allServicesData);

      const labServicesHashData: Record<string, SelectedLabService> = allServicesData.reduce(
        (acc: { [key: string]: SelectedLabService }, curr: SelectedLabService) => {
          acc[curr.id] = {
            ...curr,
            selected: false,
          };
          return acc;
        },
        {},
      );

      if (couponLabServices) {
        couponLabServices.forEach((labService: SelectedLabService) => {
          if (labServicesHashData[labService.id]) {
            labServicesHashData[labService.id].selected = true;
          }
        });
        setLabServicesHash(labServicesHashData);
        setDisplayedLabServices(allServicesData);
      } else {
        Swal.fire({
          title: "Error",
          text: "No se pudo obtener los servicios para el cupón con id " + id,
          icon: "error",
          willClose: () => {
            navigate(`/coupons`);
          },
        });
      }
    } catch (err) {
      await Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Ok",
      });
      debug(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);
  return (
    <Layout>
      {/* @ts-expect-error TokenValidator cannot be used as a JSX element */}
      <TokenValidator>
        {/* @ts-expect-error PrivateMiducRoute cannot be used as a JSX element */}
        <PrivateMiducRoute>
          <div className="min-h-screen w-full bg-gray-100 relative overflow-y-auto p-4">
            <div className="p-10 max-w-2xl mx-auto bg-white border border-gray-300 rounded-md">
              <div className="mb-8">
                <h1 className="mr-8 text-4xl">Editar cupón {coupon?.referral_code}</h1>
              </div>
              <div className={clsx("w-full", "mb-12", "flex flex-col")}>
                <p className={clsx("mb-2", "text-lg")}>Elige el país donde quieres que el cupon tenga efecto</p>
                <Select value={country} onChange={(e) => setCountry(e.target.value as Country)} fullWidth>
                  {Object.entries(Country).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <CouponForm
                allLabServices={allLabServices}
                setAllLabServices={setAllLabServices}
                labServicesHash={labServicesHash}
                setLabServicesHash={setLabServicesHash}
                displayedLabServices={displayedLabServices}
                setDisplayedLabServices={setDisplayedLabServices}
                country={country || undefined}
                coupon={coupon}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          </div>
        </PrivateMiducRoute>
      </TokenValidator>
    </Layout>
  );
};

export default EditCoupon;
